import React, { useState, useEffect } from "react";
import { Slider, Typography, Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import styles from "./Calculator.module.css";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: "#61D1B5", // Color del thumb
        },
        track: {
          color: "#61D1B5", // Color del track
        },
        rail: {
          color: "#ccc", // Color del rail
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "16px", // Cambia el tamaño de fuente de los Tooltips globalmente
        },
      },
    },
  },
});

function Calculator({ onLoad }) {
  // Estados para los inputs
  const [facturacionAnual, setFacturacionAnual] = useState(10000000);
  const [numeroDocumentosAnuales, setNumeroDocumentosAnuales] = useState(50000);
  const [tiempoDedicado, setTiempoDedicado] = useState(40);
  const [numeroEmpleados, setNumeroEmpleados] = useState(15);
  const [pagoMedio, setPagoMedio] = useState(2000);
  const [gastosExternos, setGastosExternos] = useState(100000);

  // Constantes
  const porcentajeEficiencia = 0.5; // 5%
  const nuestroTicketMedio = 700;

  // Formato de números
  const formatNumber = (num) => {
    return num.toLocaleString("es-ES", { maximumFractionDigits: 2 });
  };

  useEffect(() => {
    if (onLoad) {
      onLoad();
    }
  }, [onLoad]);

  // Cálculos

  // Gastos actuales
  const gastosActuales =
    (tiempoDedicado / 100) * pagoMedio * numeroEmpleados * 12 +
    gastosExternos * 12;

  // Nuevos gastos con SafeCon
  let nuevosGastos =
    gastosActuales * (1 - porcentajeEficiencia) + nuestroTicketMedio * 12;

  // Ahorro en gastos operativos
  let ahorroGastos = gastosActuales - nuevosGastos;

  // Evitar ahorro negativo
  if (ahorroGastos < 0) {
    ahorroGastos = 0;
  }

  // Beneficio es el ahorro en gastos
  let beneficio = ahorroGastos;

  // Coste por documento antes y después
  let costoPorDocumentoAntes = gastosActuales / numeroDocumentosAnuales;
  let costoPorDocumentoDespues = nuevosGastos / numeroDocumentosAnuales;

  // Validación para evitar costos negativos o divisiones por cero
  if (numeroDocumentosAnuales <= 0) {
    costoPorDocumentoAntes = 0;
    costoPorDocumentoDespues = 0;
  } else {
    // Evitar costos negativos
    costoPorDocumentoAntes = Math.max(costoPorDocumentoAntes, 0);
    costoPorDocumentoDespues = Math.max(costoPorDocumentoDespues, 0);
  }

  // Datos para la gráfica
  const chartData = {
    labels: ["Gastos Actuales", "Ahorro con SafeCon"],
    datasets: [
      {
        data: [gastosActuales, ahorroGastos],
        backgroundColor: ["#64b5f6", "#81e6d9"],
        hoverBackgroundColor: ["#42a5f5", "#4db6ac"],
        borderWidth: 0,
      },
    ],
  };

  // Opciones de la gráfica
  const chartOptions = {
    cutout: "70%",
    plugins: {
      legend: {
        display: false, // Deshabilitamos la leyenda predeterminada
      },
    },
  };

  // Marks para los sliders (mostrar límites)
  const createMarks = (min, max) => [
    { value: min, label: formatNumber(min) },
    { value: max, label: formatNumber(max) },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div id="valuecalculator" className={styles.calculadoraContainer}>
        {/* Contenedor del Título */}
        <div className={styles.tituloContainer}>
          <h1 className={styles.title}>Rentabilidad con SafeCon</h1>
        </div>
        {/* Contenedor Principal */}
        <div className={styles.contenidoContainer}>
          {/* Contenedor de Sliders */}
          <div className={styles.slidersContainer}>
            {/* Sliders */}
            <div className={styles.sliderItem}>
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Nº de Documentos Anuales:{" "}
                {formatNumber(numeroDocumentosAnuales)}
                <Tooltip title="Indique el número de documentos que gestiona su empresa aproximadamente al año">
                  <IconButton size="medium" style={{ marginLeft: 8 }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Typography>
              <Slider
                value={numeroDocumentosAnuales}
                min={1000}
                max={100000}
                step={1000}
                onChange={(e, val) => setNumeroDocumentosAnuales(val)}
                valueLabelDisplay="auto"
                marks={createMarks(1000, 100000)}
              />
            </div>

            <div className={styles.sliderItem}>
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Porcentaje de Tiempo Dedicado por Empleado a Gestionar
                Documentos: {tiempoDedicado}%
                <Tooltip title="Indique el porcentaje de la jornada laboral de sus empleados (a jornada completa) que dedican de media a gestionar documentos para su empresa">
                  <IconButton size="medium" style={{ marginLeft: 8 }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Typography>
              <Slider
                value={tiempoDedicado}
                min={0}
                max={100}
                step={5}
                onChange={(e, val) => setTiempoDedicado(val)}
                valueLabelDisplay="auto"
                marks={createMarks(0, 100)}
              />
            </div>

            <div className={styles.sliderItem}>
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Nº de Empleados Involucrados en la Gestión Documental:{" "}
                {numeroEmpleados}
                <Tooltip title="Indique el número de empleados aproximado que se dedican a la gestión documental en su empresa.">
                  <IconButton size="medium" style={{ marginLeft: 8 }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Typography>
              <Slider
                value={numeroEmpleados}
                min={1}
                max={100}
                step={1}
                onChange={(e, val) => setNumeroEmpleados(val)}
                valueLabelDisplay="auto"
                marks={createMarks(1, 100)}
              />
            </div>

            <div className={styles.sliderItem}>
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Pago Medio a Empleados: {formatNumber(pagoMedio)} €/mes
              </Typography>
              <Slider
                value={pagoMedio}
                min={500}
                max={10000}
                step={100}
                onChange={(e, val) => setPagoMedio(val)}
                valueLabelDisplay="auto"
                marks={createMarks(500, 10000)}
              />
            </div>

            <div className={styles.sliderItem}>
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Gastos Externos de Gestión de Documentos:{" "}
                {formatNumber(gastosExternos)} €/mes
                <Tooltip title="Indique el gasto medio dedicado a servicios documentales externos a su empresa: Auditorías, Despachos...">
                  <IconButton size="medium" style={{ marginLeft: 8 }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Typography>
              <Slider
                value={gastosExternos}
                min={0}
                max={10000}
                step={100}
                onChange={(e, val) => setGastosExternos(val)}
                valueLabelDisplay="auto"
                marks={createMarks(0, 10000)}
              />
            </div>
          </div>

          {/* Contenedor de la Gráfica y Resultados */}
          <div className={styles.graficaContainer}>
            {/* Resultados */}
            <div className={styles.resultadosContainer}>
              <table className={styles.resultadosTable}>
                <tbody>
                  <tr>
                    <td className={styles.resultadoLabel}>
                      <strong>Coste por Documento sin SafeCon:</strong>
                    </td>
                    <td
                      className={styles.resultadoValor}
                      style={{ color: "red" }}
                    >
                      {formatNumber(costoPorDocumentoAntes)} €
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.resultadoLabel}>
                      <strong>Coste por Documento con SafeCon:</strong>
                    </td>
                    <td
                      className={styles.resultadoValor}
                      style={{ color: "green" }}
                    >
                      {formatNumber(costoPorDocumentoDespues)} €
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Gráfica */}
            <div className={styles.chartContainer}>
              <div className={styles.chartWrapper}>
                <Doughnut data={chartData} options={chartOptions} />

                {/* Contenido central */}
                <div className={styles.centerContent}>
                  <div className={styles.label}>
                    <div className={styles.iconContainer}>
                      <div
                        className={styles.colorBox}
                        style={{
                          backgroundColor: "#81e6d9",
                        }}
                      ></div>
                      <TrendingUpIcon className={styles.icon} />
                    </div>
                    <div className={styles.text}>
                      <p>Ahorro Anual</p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2em",
                          color: beneficio > 0 ? "green" : "black",
                        }}
                      >
                        {beneficio > 0
                          ? `+${formatNumber(beneficio)} €`
                          : "No hay ahorro"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Leyenda Personalizada */}
              <div className={styles.labelsContainer}>
                <div className={styles.label}>
                  <div className={styles.iconContainer}>
                    <div
                      className={styles.colorBox}
                      style={{
                        backgroundColor: "#64b5f6",
                      }}
                    ></div>
                    <MonetizationOnIcon className={styles.icon} />
                  </div>
                  <div className={styles.text}>
                    <p>Gastos Actuales en Gestión Documental</p>
                    <p style={{ fontWeight: "bold", fontSize: "1em" }}>
                      {formatNumber(gastosActuales)} €
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Calculator;
