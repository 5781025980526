import React, { useState, useEffect } from "react";
import styles from "./HomeHeader.module.css";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../../assets/Animations/SafeCon.json";

export default function HomeHeader() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const words = ["despachos.", "auditorías.", "M&A.", "ingenierías."];

  const defaultOptions = {
    loop: true, // Cambia a false si no quieres que se repita
    autoplay: true, // Comienza automáticamente
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Efecto para intervalo del banner para que cambie

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2500);
    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <>
      <div className={styles.fill}>
        <div className={styles.fondoContainer}>
          <div className={styles.textoContainer}>
            <ul className={styles.descripcionEmpresa}>
              <h1 className={styles.nombreEmpresa}>Asegura y negocia </h1>
              <h1 className={styles.nombreEmpresa}>
                <span className={styles.palabraGris}>documentos legales</span>
              </h1>
              <div className={styles.cambiandoPalabras}>
                {words[currentWordIndex]}
              </div>
              <p className={styles.description}>
                Controla las versiones de tus documentos legales con tu equipo y
                negocia contratos con tus clientes sin malentendidos y de manera
                segura.
              </p>
              <Link
                to="https://calendly.com/gerardo-safecon/conoce-safecon-con-nuestro-ceo"
                className={styles.buttonFee}
              >
                Solicitar demo
              </Link>
            </ul>
          </div>
          <div className={styles.imagenContainer}>
            <Lottie
              isClickToPauseDisabled={true}
              options={defaultOptions}
              width={"100%"}
            />
          </div>
        </div>
      </div>
    </>
  );
}
