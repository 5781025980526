import React, { useRef, Suspense, lazy, useState, useEffect } from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";

import logo from "../assets/images/LogoDef.webp";
import {
  Authenticator,
  useTheme,
  View,
  Image,
  Text,
} from "@aws-amplify/ui-react";

import Fallback from "../assets/Animations/Fallback";
import Banner from "../pages/Home/Landing/Banner";
import PrivacyPolicy from "../pages/Home/Landing/Privacy";
import InvitationHandler from "../pages/Signup/InvitationHandler";
import NotFound from "../pages/Signup/NotFound";

import ProtectedRoute from "./ProtectedAPIroute";
import Confirmation from "../pages/Home/Pricing/Confirmation";
import PaymentError from "../pages/Home/Pricing/PaymentError";
import EditorCliente from "../pages/Dashboard/ClienteEditor/EditorCliente";
import Success from "../pages/Dashboard/ClienteEditor/Success";
import ScreenBlocker from "./ScreenBlocker";

import Calculator from "../pages/Home/Landing/Calculator";
import HomeHeader from "../pages/Home/Landing/HomeHeader";

const CookieBanner = lazy(() => import("./CookieBanner"));
const PoliticaCookies = lazy(() =>
  import("../pages/Home/Landing/PoliticaCookies")
);

const TerminosCondiciones = lazy(() =>
  import("../pages/Home/Landing/TerminosCondiciones")
);

const RegistrationController = lazy(() =>
  import("../pages/Signup/RegistrationController")
);

const AppHeader = lazy(() => import("../pages/Header/WebHeaderRender"));

// const HomeHeader = lazy(() => import("../pages/Home/Landing/HomeHeader"));
const HomePipeline2 = lazy(() =>
  import("../pages/Home/Landing/HomePipeline copy")
);

const Privacidad = lazy(() => import("../pages/Home/Landing/Privacidad"));
const Footer = lazy(() => import("../pages/Home/Landing/Footer"));

const Colab = lazy(() =>
  import("../pages/Home/CLM/Sistema Colaborativo/Colab")
);

const ElaboracionHome = lazy(() =>
  import("../pages/Home/CLM/Elaboracion de contratos/ElaboracionHome")
);

const Pruebalo = lazy(() => import("../pages/Home/Pricing/Pruebalo"));

const PruebaloDash = lazy(() => import("../pages/Home/Landing/PruebaloDash"));

const DashboardRoutes = lazy(() => import("../Routes/Dashboard-routes"));
const CLM = lazy(() =>
  import("../pages/Home/CLM/Contract Lifecycle Management/CLM")
);
const CLMList = lazy(() =>
  import("../pages/Home/CLM/Contract Lifecycle Management/CLM-List")
);
const Neghome = lazy(() =>
  import("../pages/Home/CLM/Negociacion de contratos/Neghome")
);

const Negexplain = lazy(() =>
  import("../pages/Home/CLM/Negociacion de contratos/Negexplain")
);

const Almacenamiento = lazy(() =>
  import("../pages/Home/CLM/Almacenamiento de contratos/Almacenamiento")
);

const Firma15 = lazy(() =>
  import("../pages/Home/CLM/Firma electronica/Firma1.5")
);

const Almintegraciones = lazy(() =>
  import("../pages/Home/CLM/Almacenamiento de contratos/Almintegraciones")
);
const Pricing = lazy(() => import("../pages/Home/Pricing/Pricing"));
const Rentabilidad = lazy(() => import("../pages/Home/Landing/Rentabilidad"));
const Integraciones = lazy(() => import("../pages/Home/Landing/Integraciones"));
const Quienessomos = lazy(() =>
  import("../pages/Home/Acercade.js/Quienessomos/Quienessomos")
);
const Sostenibilidad = lazy(() =>
  import("../pages/Home/Acercade.js/Sostenibilidad/Sostenibilidad")
);
const Sostenibilidad2 = lazy(() =>
  import("../pages/Home/Acercade.js/Sostenibilidad/Sostenibilidad2")
);
const Sostenibilidad3 = lazy(() =>
  import("../pages/Home/Acercade.js/Sostenibilidad/Sostenibilidad3")
);

const PruebaGratis = lazy(() => import("../pages/Home/Landing/Demo"));
const Demo = lazy(() => import("../pages/Home/Landing/ContactSolicitud"));

const ScrollToTopButton = lazy(() =>
  import("../pages/Header/ScrollToTopButton")
);

const SolicitarPlan = lazy(() => import("../pages/Home/Pricing/SolicitarPlan"));

const Emailvalidation = lazy(() => import("../pages/Signup/Emailvalidation"));


const formFields = {
  signIn: {
    username: {
      placeholder: "usuario@dominio.com", // Ejemplo de placeholder personalizado
      label: "Email", // Cambiar "Username" por "Email"
      inputProps: {
        // Asegurar que el tipo de input sea email para validación
        type: "email",
      },
    },
  },

  forgotPassword: {
    username: {
      label: "Email", // Cambia "Username" por "Email" si utilizas emails como usernames
      placeholder: "Tu correo electrónico",
      inputProps: {
        // Asegúrate de que el navegador entienda que es un campo de email
        type: "email",
        required: true, // Marcar como campo obligatorio
      },
    },
  },
  resetPassword: {
    // Asume que estamos personalizando también los campos de la página de reseteo
    confirmation_code: {
      label: "Código de Confirmación",
      placeholder: "Introduce el código recibido",
    },
    password: {
      label: "Nueva Contraseña",
      placeholder: "Introduce tu nueva contraseña",
    },
  },

  confirmVerifyUser: {
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="SafeCon logo" src={logo} width={200} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
};

const GraphQLPlayground = () => {
  window.location.href = "/graphql"; // Redirige al playground predeterminado
  return null;
};


export default function HomeRoutes() {
  const feesRef = useRef(null);
  const [isRateLimited, setIsRateLimited] = useState(false); // Nuevo estado para controlar el rate limiting
  const [isSubmitting, setIsSubmitting] = useState(false); // Controlar si el formulario está siendo enviado

  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const location = useLocation();
  const [isCalculatorLoaded, setIsCalculatorLoaded] = useState(false);


  useEffect(() => {
    if (location.hash === "#valuecalculator" && isCalculatorLoaded) {
      const element = document.getElementById("valuecalculator");
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location, isCalculatorLoaded]);


  // Efecto para comprobar el tamaño de pantalla
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1100) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    };

    handleResize(); // Comprobamos inicialmente
    window.addEventListener("resize", handleResize); // Escuchamos cambios en el tamaño de la pantalla

    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  // Comprobamos si la pantalla es pequeña y si la ruta es /dashboard o comienza por /dashboard
  if (isMobileScreen && window.location.pathname.startsWith("/ver-propuesta")) {
    return <ScreenBlocker />;
  }

  return (
    <>
      <Suspense fallback={<Fallback />}>
        <CookieBanner />
        <Routes>
          <Route
            path="/graphql"
            element={
              <Authenticator hideSignUp={true}>
                <ProtectedRoute />
              </Authenticator>
            }
          />
          <Route
            path="/document-creation"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <CLM />

                <CLMList />
                <Footer />
              </>
            }
          />
          <Route
            path="/version-control"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <ElaboracionHome />
                <Pruebalo />
                <Footer />
              </>
            }
          />
          <Route
            path="/document-negotiation"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Neghome />
                <HomePipeline2 />
                <Negexplain />
                <Footer />
              </>
            }
          />
          <Route
            path="/secure-storage"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Almacenamiento />
                <Almintegraciones />
                <Footer />
              </>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Pricing />
                <Footer />
              </>
            }
          />
          <Route
            path="/about-us"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Quienessomos />
                <Footer />
              </>
            }
          />
          <Route
            path="/sustainability"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Sostenibilidad />
                <Sostenibilidad2 />
                <Sostenibilidad3 />
                <Footer />
              </>
            }
          />
          <Route
            path="/solicitar-demo"
            element={
              <>
                <PruebaGratis />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Demo />
              </>
            }
          />
          <Route
            path="/solicitar-plan"
            element={
              <>
                <SolicitarPlan />
              </>
            }
          />
          <Route
            path="/handle-invitation"
            element={
              <>
                <InvitationHandler />
              </>
            }
          />
          <Route
            path="/ver-propuesta"
            element={
              <>
                <EditorCliente />
              </>
            }
          />
          <Route
            path="/contrapropuesta-enviada"
            element={
              <>
                <Success />
              </>
            }
          />
          <Route
            path="/404"
            element={
              <>
                <NotFound />
                <Footer />
              </>
            }
          />
          <Route
            path="/politica-privacidad"
            element={
              <>
                <PrivacyPolicy />
              </>
            }
          />
          <Route
            path="/politica-cookies"
            element={
              <>
                <PoliticaCookies />
              </>
            }
          />
          <Route
            path="/terms"
            element={
              <>
                <TerminosCondiciones />
              </>
            }
          />
          
          
          <Route path="/payment-confirmation" element={<Confirmation />} />
          <Route path="/respuesta-enviada" element={<Success />} />
          <Route path="/payment-error" element={<PaymentError />} />
          <Route path="/emailvalidation" element={<Emailvalidation />} />
          <Route path="/signup" element={<RegistrationController />} />
          <Route
            path="/dashboard/*"
            element={
              <Authenticator
                formFields={formFields}
                components={components}
                hideSignUp={true}
                onLogin={async (signIn) => {
                  try {
                    setIsSubmitting(true); // Desactivar el botón mientras se envía la solicitud
                    await signIn(); // Intentar el inicio de sesión
                  } catch (error) {
                    if (
                      error.code === "TooManyRequestsException" ||
                      error.message.includes("Too Many Requests")
                    ) {
                      alert(
                        "Has excedido el límite de intentos de inicio de sesión. Por favor, espera un minuto."
                      );
                      setIsRateLimited(true); // Activar el estado de rate limiting
                      setTimeout(() => {
                        setIsRateLimited(false); // Reactivar el botón después de un minuto
                      }, 60000); // Esperar un minuto (60,000 ms)
                      return;
                    }
                    console.error("Error al iniciar sesión:", error);
                  } finally {
                    setIsSubmitting(false); // Permitir reactivar el botón después de que se procese la solicitud
                  }
                }}
              >
                {/* Aquí no es necesario sobreescribir el botón, simplemente controla el estado de envío */}
                <DashboardRoutes />
              </Authenticator>
            }
          />
          <Route
            path="/collaborative-system"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Colab />
                <Pruebalo />
                <Footer />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <HomeHeader feesRef={feesRef} />
                <Banner />
                <Firma15 />
                <PruebaloDash />
                <Calculator onLoad={() => setIsCalculatorLoaded(true)}/>
                <Integraciones />
                <Privacidad />
                <Footer />
              </>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
}
