import React from "react";
import styles from "./Privacy.module.css"; // Importamos el CSS modular

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Política de Privacidad de SafeCon</h1>
      <p className={styles.lastUpdated}>
        Última modificación: 22 de septiembre de 2024
      </p>

      <section>
        <h2 className={styles.subheader}>1. Introducción</h2>
        <p>
          SafeCon Technologies SLU ("SafeCon") valora la privacidad y la
          confidencialidad de la información personal de sus usuarios. Esta
          Política de Privacidad explica cómo recopilamos, usamos, protegemos y
          compartimos la información personal y los datos documentales que usted
          proporciona al utilizar nuestro software y servicios. Esta política
          también regula el tratamiento de cualquier documento o dato gestionado
          a través de la Plataforma SafeCon por parte del Cliente. Estamos
          comprometidos con el cumplimiento del Reglamento General de Protección
          de Datos de la Unión Europea ("RGPD") y la Ley Orgánica de Protección
          de Datos Personales y garantía de los derechos digitales ("LOPDGDD").
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          2. Datos Personales que Recopilamos
        </h2>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Información de Contacto: Nombres, direcciones de correo electrónico,
            números de teléfono y direcciones postales.
          </li>
          <li className={styles.listItem}>
            Información de la Cuenta: Nombres y datos de usuario, e información
            de facturación.
          </li>
          <li className={styles.listItem}>
            Datos de Uso: Información sobre cómo utiliza nuestros servicios,
            incluidas las funciones activadas, la frecuencia de uso, la duración
            de las sesiones, dirección IP, tipo de dispositivo y navegador.
          </li>
          <li className={styles.listItem}>
            Datos del Documento y Anexos: Detalles sobre los contratos y
            documentos anexos que gestiona a través de nuestros servicios,
            incluyendo las partes involucradas, objeto del documento, términos y
            documentos relacionados. Todos los documentos y anexos se tratan de
            manera confidencial y con encriptación en reposo y tránsito.
          </li>
        </ul>
      </section>

      <section>
        <h2 className={styles.subheader}>3. Uso de Información Personal</h2>
        <p>
          Proporcionar y Mejorar Nuestros Servicios: Utilizamos su información
          personal para entregar y mejorar nuestros servicios, asegurando que se
          adapten a sus necesidades y expectativas.
        </p>
        <p>
          Comunicación: Para enviarle actualizaciones y comunicaciones sobre
          nuestros servicios y su cuenta, incluyendo notificaciones sobre
          cambios en nuestras políticas o condiciones.
        </p>
        <p>
          Cumplimiento Legal y Regulatorio: Cumplir con nuestras obligaciones
          legales, responder a solicitudes legales y proteger nuestros derechos
          y los de nuestros usuarios.
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          4. Confidencialidad y Compartición de Información Personal
        </h2>
        <p>
          Proveedores de Servicios: Solo compartimos información necesaria con
          proveedores que nos ayudan a operar y mejorar nuestros servicios bajo
          estrictos términos de confidencialidad. Estos proveedores incluyen,
          pero no se limitan a, servicios de alojamiento en la nube como Amazon
          Web Services (AWS), servicios de pago y plataformas de comunicación.
          Estos proveedores cumplen con el RGPD o poseen certificaciones de
          seguridad reconocidas.
        </p>
        <p>
          Asesores Legales y Financieros: En caso de una disputa legal o
          auditoría financiera, podemos compartir información necesaria para la
          resolución del asunto con nuestros asesores legales y financieros.
        </p>
        <p>
          Autoridades Gubernamentales: Compartimos información personal cuando
          es requerido por ley, siempre bajo un proceso legal adecuado, y solo
          la cantidad mínima de información necesaria.
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>5. Derechos de Privacidad</h2>
        <p>
          Usted tiene los siguientes derechos con respecto a su información
          personal:
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Acceso: Solicitar una copia de la información personal que tenemos
            sobre usted.
          </li>
          <li className={styles.listItem}>
            Rectificación: Corregir cualquier información personal incorrecta o
            incompleta.
          </li>
          <li className={styles.listItem}>
            Supresión: Solicitar la eliminación de su información personal
            cuando ya no sea necesaria para los fines para los que fue
            recopilada.
          </li>
          <li className={styles.listItem}>
            Limitación: Restringir el procesamiento de su información personal
            en ciertas circunstancias.
          </li>
          <li className={styles.listItem}>
            Oposición: Oponerse al procesamiento de su información personal en
            determinadas situaciones.
          </li>
          <li className={styles.listItem}>
            Portabilidad: Solicitar la transferencia de su información personal
            a otra organización o directamente a usted.
          </li>
        </ul>
        <p>
          Para ejercer estos derechos, contacte con nosotros a través de
          support@safecon.app. Nos comprometemos a responder a sus solicitudes
          relacionadas con sus derechos de privacidad en un plazo máximo de{" "}
          <strong>30 días</strong>.
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>6. Seguridad de la Información</h2>
        <p>
          Implementamos medidas de seguridad técnicas y organizativas adecuadas
          para proteger su información personal contra el acceso no autorizado,
          la pérdida, alteración, divulgación o destrucción. Almacenamos los
          datos con la privacidad y seguridad proporcionada por Amazon Web
          Services (AWS), garantizando altos estándares de seguridad. Entre
          estas medidas se incluyen:
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Encriptación en Tránsito:</strong> Todas las comunicaciones
            entre los usuarios y nuestra plataforma se realizan a través de
            protocolos seguros HTTPS/TLS, garantizando que los datos están
            encriptados durante su transmisión.
          </li>
          <li className={styles.listItem}>
            <strong>Encriptación en Reposo:</strong> Los documentos y datos
            almacenados en nuestros servidores están encriptados utilizando
            encriptación del lado del servidor con claves gestionadas por Amazon
            S3 (SSE-S3). Esto asegura que los datos estén protegidos mientras se
            almacenan en nuestros sistemas.
          </li>
          <li className={styles.listItem}>
            <strong>Control de Acceso:</strong> Implementamos estrictos
            controles de acceso y autenticación para garantizar que solo
            personal autorizado pueda acceder a la información confidencial.
          </li>
          <li className={styles.listItem}>
            <strong>Auditorías periódicas de seguridad:</strong> SafeCon realiza
            auditorías periódicas de seguridad para asegurarse de que las
            medidas implementadas cumplen con los estándares más altos de
            protección de datos.
          </li>
        </ul>
      </section>

      <section>
        <h2 className={styles.subheader}>7. Retención de Datos</h2>
        <p>
          Retenemos su información personal solo por el tiempo necesario para
          cumplir con los propósitos establecidos en esta política, a menos que
          la ley requiera un período más largo. Por ejemplo, los datos de
          facturación pueden ser retenidos durante un periodo mayor en
          cumplimiento de las obligaciones fiscales y contables. Una vez que la
          información personal ya no sea necesaria, la eliminaremos o la
          anonimizaremos de manera segura.
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          8. Cambios en la Política de Privacidad
        </h2>
        <p>
          Nos reservamos el derecho de modificar esta política de privacidad en
          cualquier momento. Cualquier cambio significativo será notificado a
          los usuarios a través de{" "}
          <strong>
            correo electrónico o una notificación dentro de la plataforma
            SafeCon
          </strong>{" "}
          antes de su implementación, y la versión actualizada de la política
          estará siempre disponible en nuestro sitio web.
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>9. Contacto</h2>
        <p>
          Si tiene preguntas sobre esta política o sobre cómo gestionamos su
          información personal, puede contactarnos en support@safecon.app.
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>10. Aceptación de la Política</h2>
        <p>
          Al usar nuestros servicios, usted acepta las prácticas descritas en
          esta Política de Privacidad. Si no está de acuerdo con esta política,
          le recomendamos no utilizar nuestros servicios.
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          11. Disposiciones Adicionales para España
        </h2>
        <p>
          <strong>Delegado de Protección de Datos:</strong> El Delegado de
          Protección de Datos supervisa nuestro cumplimiento del RGPD y la
          LOPDGDD. Contacto: support@safecon.app.
        </p>
        <p>
          <strong>Derechos Adicionales:</strong> Además de los derechos
          mencionados anteriormente, tiene derecho a presentar una reclamación
          ante la Agencia Española de Protección de Datos (AEPD) si considera
          que sus derechos no han sido respetados. Puede presentar una
          reclamación en su sitio web:{" "}
          <a
            href="https://www.aepd.es"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.aepd.es
          </a>
          .
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>12. Aceptación de la lectura</h2>
        <p>
          El Cliente declara expresamente que es un profesional, por lo que la
          contratación de los Servicios de SafeCon se realiza exclusivamente
          para fines relacionados con su actividad comercial, negocio, oficio o
          profesión. Asimismo, el Cliente reconoce haber leído y comprendido
          toda la información relativa a los Servicios y todos los términos y
          disposiciones contenidos en este Contrato, por lo que afirma que son
          suficientes para la exclusión de error en el consentimiento y, por
          tanto, los acepta plena y expresamente. El Cliente reconoce haber
          leído y aceptado las Condiciones de Uso del Sitio Web y la Política de
          Privacidad.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
